<template>
  <v-container>
    <div class="text-h2" style="margin:20px 0">{{ingredient.charAt(0).toUpperCase() + ingredient.slice(1)}}</div>
    <div v-if="description">
      <div class="text-h3" style="margin:20px 0">Beskrivelse</div>
      
      <div v-html="description"></div>
    </div>
    
    <div class="text-h3" style="margin:20px 0" v-if="nutrients.length > 0">Næringsinnhold</div>
    <v-divider></v-divider>
    <div v-for="nutrientCategory in nutrients" v-bind:key="nutrientCategory.title">
    <div class="text-h4" style="margin:20px 0 ">{{nutrientCategory.title}}</div>
    <v-row class="my-4">
      
                  <v-col cols="4" sm="2"
                  v-for="(nutrient, nutes_index) in nutrientCategory.nutrients" v-bind:key="nutrient.title+nutes_index">
                  <div
                    class="text-center mx-2"
                  >
                  <p class="my-1">
                  {{nutrient.title}} <a v-if="nutrient.link" rel="help" target="_blank" :href="nutrient.link">[?]</a>
                  </p>
                  <v-progress-circular
                      :rotate="-90"
                      :size="90"
                      :width="12"
                      :value="nutrient.value/nutrient.multiplier"
                      :color="nutrient.color"
                    >
                      {{ nutrient.value }} {{nutrient.measurement}}
                    </v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
    </div>
  </v-container>
</template>
<script>
  let data = {
    ingredient:'',
    nutrients:[],
    description:''
  }
  if(!(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered) && window.__IS_PRERENDERED && !window.__LOADED){
    if(window.__INIT_STATE__){
      console.log("FETCHING FROM INIT STATE");
      data = window.__INIT_STATE__;
      //data.ingredient = window.__INIT_STATE__.ingredient;
    } else {
      console.log("PRERENDERED BUT MISSING INIT STATE...");
    }
  }
  export default {
   metaInfo(){
      return {
        // override the parent template and just use the above title only
        title: this.ingredient.charAt(0).toUpperCase() + this.ingredient.slice(1),
        // all titles will be injected into this template
        titleTemplate: '%s | Brukmat',
        meta: [
          { name: 'description', content: 'Komplett næringsinnhold og annen vitenskapelig data om '+ this.ingredient+'.'}
        ]
      }
    },
    data:()=> (data),
    watch: {
      '$route': 'getIngredient'
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.getIngredient();
    },
    methods: {
      getIngredientFromUrl: function(title, keywords){
        
        console.log("Title:",title, "Keywords:",keywords);
        if(keywords === undefined){
          keywords = [];
        }
        let headers = {
                  'Content-Type': 'application/json'
                };
        // If keyword is empty...
        if(keywords[0] == "" || keywords[0] == " "){
          keywords = [];
        }
        console.log("POST Title:",title, "Keywords:",keywords);
        let body = {
          title:title,
          exact:true,
          keywords:keywords.map(keyword=>keyword.trim())
        };
        let allPromises = [];
        allPromises.push(this.getDescription(title, keywords));
        console.log(body);
        allPromises.push(this.$axios.post('https://api.brukmat.no/ingredients/keyword/', body, {headers:headers}).then(async (res)=>{
          let data = res.data[0];
          // If no data was returned... (couldn't find it)
          if(data == undefined){
            window.location.replace("/404/ingrediens");
          }
          if(data.keywords.length === 0){
            this.ingredient = data.product;
          } else {
            this.ingredient = data.product+" - "+data.keywords.join(", ");
          }
          if(res.data.length > 1){
            console.log("Got more than 1 ingredient from search...");
          }

          this.nutrients = await this.formatNutrients(data);
        }));
        Promise.allSettled(allPromises).then(()=>{
          console.log("Finished loading from API", [this.ingredient, this.nutrients, this.description]);
          // Save state to variable
          if(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered){
          //this.description = "PRERENDERED!!!";
          const script = document.createElement(`script`);
          let state = {
            ingredient:this.ingredient,
            nutrients:this.nutrients,
            description:this.description
          }
          script.innerHTML = `window.__INIT_STATE__ = ${JSON.stringify(state)}`;
          document.head.appendChild(script);
          window.status = "ready";
          }
        })
        //TODO Save to db
      },
      formatNutrients: async function(items){
        let dataScheme = await this.$scheme;
        let unflattened = unflattenItem(items);
        console.log(unflattened);
        let nutrients = unflattened.nutrients.map((nutrient)=>{
          //console.log(nutrient.title, dataScheme[nutrient.title]);
          if(dataScheme[nutrient.title]){
            // If hidden
            /*if(dataScheme[nutrient.title].hide == "true"){

            }*/
            return {
              color:dataScheme[nutrient.title].color || 'grey',
              title:dataScheme[nutrient.title].title || nutrient.title,
              value:nutrient.value,
              multiplier:dataScheme[nutrient.title].multiplier || 1,
              measurement:dataScheme[nutrient.title].measurement,
              category:dataScheme[nutrient.title].category || 'Ukategorisert',
              link:dataScheme[nutrient.title].link
            }
          } else {
            return {
              color:'grey',
              title:nutrient.title,
              value:nutrient.value,
              multiplier:1,
              category:"Ukategorisert"
            }
          }
        });
        let nutesWithCategories = nutrients.reduce((acc, nute)=>{
          if(!acc) acc={};
          if(!acc[nute.category]) acc[nute.category] = [];
          acc[nute.category].push(nute);
          return acc;
        }, {});
        let nuteArray = [];
        for(let i in nutesWithCategories){
          nuteArray.push({title:i, nutrients:nutesWithCategories[i]});
        }
        return nuteArray;
      },
      getDescription: async function(title, keywords){
        let body = {
          product:title,
          keywords:keywords
        };
        let headers = {
          'Content-Type': 'application/json'
        };
        console.log(body);
        return this.$axios.post('https://api.brukmat.no/get/ingredient/description', body, {headers:headers})
        .then((res)=>{
          console.log(res.data);
          console.log(res.data.http_code);
          if(res.data.status === 1){
            this.description = res.data.data;
          }
        })
      },
      getIngredient: function(){
        if(!(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered) && window.__IS_PRERENDERED && !window.__LOADED){
          console.log("Is prerendered");
          window["__LOADED"] = true;
          return;
        }
        let title = this.$route.params.ingredient;
        let keywords = this.$route.params.keywords;
        console.log("Keywords",keywords);
        // If keywords undefined... TODO Tomorrow
        let fullTitle;
        title = this.$decodeURL(title);
        if(keywords == undefined){
          fullTitle = this.$decodeURL(title);
        } else {
          keywords = keywords.split('~');
          keywords = keywords.map(keyword=>{
            return this.$decodeURL(keyword);
          });
          fullTitle = title+" - "+keywords.join(", ");
        }
        console.log("Keywords", keywords);
        // Let's deconstruct the params
        this.$db.then(db_obj=>{
          let val = fullTitle;
          var objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
          var request = objectStore.get(val);
          request.onsuccess = async (result)=>{
            let items = result.target.result;
            if(items == undefined) {
              console.log("Couldn't find ingredient...", val);
              this.getIngredientFromUrl(title, keywords);
            } else {
              console.log("Found existing ingredient");
              this.ingredient = fullTitle;
              let nuteArray = await this.formatNutrients(items);
              this.getDescription(title, keywords);
              this.nutrients = nuteArray;
            }
          }
        });
      }
    }
  }
  function unflattenItem(data) {
    if(data == undefined){
      return [];
    }
    if(data.length == 0){
      return [];
    }
      let result = {};
      for (var i in data) {
        var keys = i.split(/_(.+)/);
        result[i] = data[i];
        if(keys.length > 1){
          if(!result[keys[0]]) result[keys[0]] = [];

          result[keys[0]].push({"value":data[i], "title":keys[1]});
        }
      }
      if(result["nutrients"]){
        result["nutrients"] = result["nutrients"].sort((a,b) => (a.title.localeCompare(b.title)));
      }

    return result;
  }
</script>